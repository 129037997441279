import React from "react"
import styles from "../styles/age-gate-footer.module.scss"
import instagramLogo from "../svg/bocado-footer-instagram.svg"
import youtubeLogo from "../svg/bocado-footer-youtube.svg"
import facebookLogo from "../svg/bocado-footer-facebook.svg"
import twitterLogo from "../svg/bocado-footer-twitter.svg"
import TyC from "../documents/TyC - Stella.pdf"
import PdP from "../documents/Política de Privacidad - Stella.pdf"

const AgeGateFooter = () => (
  <div className={styles.ageGateFooterContainer}>
    <div className={styles.ageGateFooterWarning}>
      Beber con moderación, prohibida su venta a menores de 18 años. No comparta
      el contenido con menores.
    </div>
    <div className={styles.ageGateFooterLinksContainer}>
      <a
        className={styles.ageGateFooterLink}
        href={TyC}
        target="_blank"
        rel="noreferrer"
      >
        Terminos y Condiciones
      </a>
      <a
        className={styles.ageGateFooterLink}
        href={PdP}
        target="_blank"
        rel="noreferrer"
      >
        Políticas de Privacidad
      </a>
      <a
        className={styles.ageGateFooterLink}
        href="https://www.cerveceriaymalteriaquilmes.com/"
        target="_blank"
        rel="noreferrer"
      >
        Información Oficial de la Compañía
      </a>
      <div className={styles.ageGateFooterBrand}>Stella Artois ®</div>
    </div>
    <div className={styles.ageGateFooterSocialMediaContainer}>
      <a
        target="_blank"
        href="https://www.instagram.com/stellaartois.ar/"
        rel="noreferrer"
      >
        <img
          src={instagramLogo}
          alt="Instagram"
          className={styles.ageGateFooterSocialMediaLogo}
        />
      </a>
      <a
        href="https://www.youtube.com/user/StellaArtoisAR"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={youtubeLogo}
          alt="YouTube"
          className={styles.ageGateFooterSocialMediaLogo}
        />
      </a>
      <a
        target="_blank"
        href="https://www.facebook.com/StellaArtoisArgentina"
        rel="noreferrer"
      >
        <img
          src={facebookLogo}
          alt="Facebook"
          className={styles.ageGateFooterSocialMediaLogo}
        />
      </a>
      <a
        target="_blank"
        href="https://twitter.com/stellaartois_ar"
        rel="noreferrer"
      >
        <img
          src={twitterLogo}
          alt="Twitter"
          className={styles.ageGateFooterSocialMediaLogo}
        />
      </a>
    </div>
  </div>
)

export default AgeGateFooter
