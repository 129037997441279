import React, { useState, useRef, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import AgeGateFooter from "../components/age-gate-footer.jsx";
import styles from "../styles/age-gate.module.scss";
import star from "../svg/bocado-star.svg";
import { showLoaderAtom } from "../recoil/atoms.js";
import Seo from "../components/seo";
import {
    calculateAge,
    calculateMonth,
    toNumbers,
    calculateDate,
} from "../utils/age-gate";
import Cookies from "js-cookie";

const AgeGate = ({ location }) => {
    const [date, setDate] = useState({
        dd: ["", ""],
        mm: ["", ""],
        aaaa: ["", "", "", ""],
    });
    const [calendar, setCalendar] = useState("AÑO");
    const [remember, setRemember] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const inputYear1 = useRef(null);
    const inputYear2 = useRef(null);
    const inputYear3 = useRef(null);
    const inputYear4 = useRef(null);
    const inputMonth1 = useRef(null);
    const inputMonth2 = useRef(null);
    const inputDay1 = useRef(null);
    const inputDay2 = useRef(null);
    let newPath = location.state
        ? location.state.newPath
            ? location.state.newPath
            : "/"
        : "/";

    const underAgeLink =
        "https://www.cerveceriaymalteriaquilmes.com/consumo-responsable";

    const setShowLoader = useSetRecoilState(showLoaderAtom);

    useEffect(() => {
        const td = Cookies.get("_td");
        window?.dataLayer.push({
            brand: "Stella Artois",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Age Gate",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.stellaartois.com.ar/agegate",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        });
    }, []);

    const handleAge = (e) => {
        let { name, value } = e.target;
        let newDate = { ...date };
        //YEAR
        if (name === "A1") {
            if (value.length === 1 && e.target.validity.valid) {
                inputYear2.current.focus();
                newDate.aaaa[0] = value;
                setDate(newDate);
            } else {
                newDate.aaaa[0] = "";
                setDisabled(true);
                setDate(newDate);
            }
        }
        if (name === "A2") {
            if (value.length === 1 && e.target.validity.valid) {
                inputYear3.current.focus();
                newDate.aaaa[1] = value;
                setDate(newDate);
            } else {
                newDate.aaaa[1] = "";
                setDate(newDate);
                setDisabled(true);
                inputYear1.current.focus();
            }
        }
        if (name === "A3") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.aaaa[2] = value;
                setDate(newDate);
                inputYear4.current.focus();
            } else {
                newDate.aaaa[2] = "";
                setDate(newDate);
                setDisabled(true);
                inputYear2.current.focus();
            }
        }
        if (name === "A4") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.aaaa[3] = value;
                setDate(newDate);
                checkAge(newDate, "year");
            } else {
                newDate.aaaa[3] = "";
                setDate(newDate);
                setDisabled(true);
                inputYear3.current.focus();
            }
        }
        //MONTH
        if (name === "M1") {
            if (value.length === 1 && e.target.validity.valid) {
                inputMonth2.current.focus();
                newDate.mm[0] = value;
                setDate(newDate);
            } else {
                newDate.mm[0] = "";
                setDate(newDate);
                setDisabled(true);
            }
        }
        if (name === "M2") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.mm[1] = value;
                setDate(newDate);
                checkAge(newDate, "month");
            } else {
                newDate.mm[1] = "";
                setDate(newDate);
                setDisabled(true);
                inputMonth1.current.focus();
            }
        }
        //DAY
        if (name === "D1") {
            if (value.length === 1 && e.target.validity.valid) {
                inputDay2.current.focus();
                newDate.dd[0] = value;
                setDate(newDate);
            } else {
                newDate.dd[0] = "";
                setDate(newDate);
                setDisabled(true);
            }
        }
        if (name === "D2") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.dd[1] = value;
                setDate(newDate);
                checkAge(newDate, "day");
            } else {
                newDate.dd[1] = "";
                setDate(newDate);
                setDisabled(true);
                inputDay1.current.focus();
            }
        }
    };
    const checkAge = (date, who) => {
        let birthday = new Date();
        if (who === "year") {
            let year = birthday.getUTCFullYear();
            if (year - toNumbers(date.aaaa) < 18) {
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate Select Language",
                    event_action: "Intercation",
                    event_label: "No",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
                window.location.href = underAgeLink;
            }
            if (year - toNumbers(date.aaaa) >= 19) {
                if (date.aaaa.length === 4) {
                    sessionStorage.setItem("age", "true");
                    setDisabled(false);
                    window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Age Gate",
                        event_action: "Intercation",
                        event_label: "Yes",
                        interaction: false,
                        component_name: "",
                        element_text: "",
                    });
                } else {
                    sessionStorage.clear();
                    setDisabled(true);
                }
            } else {
                setCalendar("MES");
                setTimeout(() => {
                    inputMonth1.current.focus();
                }, 1);
            }
        }
        if (who === "month") {
            birthday.setDate(1);
            birthday.setMonth(toNumbers(date.mm) - 1);
            birthday.setFullYear(toNumbers(date.aaaa));
            let today = new Date();
            if (toNumbers(date.mm) - 1 > today.getMonth()) {
                setDate({
                    dd: ["", ""],
                    mm: ["", ""],
                    aaaa: ["", "", "", ""],
                });
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate Select Language",
                    event_action: "Intercation",
                    event_label: "No",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
                return (window.location.href = underAgeLink);
            }
            if (calculateMonth(birthday)) {
                sessionStorage.setItem("age", "true");
                setDisabled(false);
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Intercation",
                    event_label: "Yes",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
            } else {
                setCalendar("DÍA");
                setTimeout(() => {
                    inputDay1.current.focus();
                }, 1);
            }
        }
        if (who === "day") {
            birthday.setDate(toNumbers(date.dd));
            birthday.setMonth(toNumbers(date.mm) - 1);
            birthday.setFullYear(toNumbers(date.aaaa));
            if (calculateDate(birthday)) {
                sessionStorage.setItem("age", "true");
                setDisabled(false);
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Intercation",
                    event_label: "Yes",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Intercation",
                    event_label: "No",
                    interaction: false,
                    component_name: "full_age_gate",
                    element_text: "full_age_gate",
                });
            } else {
                setCalendar("AÑO");
                setDisabled(false);
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate Select Language",
                    event_action: "Intercation",
                    event_label: "No",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
                window.location.href = underAgeLink;
            }
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        remember && localStorage.setItem("age", "true");
        setShowLoader(false);
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Age Gate",
            event_action: "Intercation",
            event_label: "Send",
            interaction: false,
            component_name: "",
            element_text: "",
        });
        return navigate(newPath, { replace: true });
    };

    return (
        <>
            <Seo
                title="Stella Artois"
                description="La ruta gastronómica de Stella. Compartí momentos con una Stella Artois. Conocé dónde podés encontrarnos y las experiencias que tenemos para vos."
            />
            <div className={styles.ageGateContainer}>
                <StaticImage
                    src="../images/AgeGate/01.jpg"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    alt="Experiencia"
                    className={styles.ageGatePicture}
                />
                <div className={styles.ageGateContent}>
                    <img
                        className={styles.ageGateStar}
                        src={star}
                        alt="Stella Artois Star"
                    />
                    <div className={styles.ageGateTitle}>
                        ¿Sos mayor de edad?
                    </div>
                    <div className={styles.ageGateInstructions}>
                        Ingresá tu fecha de nacimiento para continuar
                    </div>
                    <form
                        className={styles.ageGateForm}
                        onSubmit={(e) => onSubmit(e)}
                    >
                        {calendar === "AÑO" && (
                            <div className={styles.ageGateInputContainer}>
                                <input
                                    required
                                    maxLength={1}
                                    max="2"
                                    name={"A1"}
                                    placeholder="A"
                                    onChange={handleAge}
                                    value={date.aaaa[0]}
                                    ref={inputYear1}
                                    type="text"
                                    pattern="[0-9]*"
                                    className={styles.ageGateInput}
                                />
                                <input
                                    required
                                    maxLength="1"
                                    max="9"
                                    name={"A2"}
                                    onChange={handleAge}
                                    placeholder="A"
                                    value={date.aaaa[1]}
                                    ref={inputYear2}
                                    type="text"
                                    pattern="[0-9]*"
                                    className={styles.ageGateInput}
                                />
                                <input
                                    required
                                    maxLength={1}
                                    max={9}
                                    name={"A3"}
                                    onChange={handleAge}
                                    placeholder="A"
                                    value={date.aaaa[2]}
                                    ref={inputYear3}
                                    type="text"
                                    pattern="[0-9]*"
                                    className={styles.ageGateInput}
                                />
                                <input
                                    required
                                    maxLength={1}
                                    max={9}
                                    name={"A4"}
                                    onChange={handleAge}
                                    placeholder="A"
                                    value={date.aaaa[3]}
                                    ref={inputYear4}
                                    type="text"
                                    pattern="[0-9]*"
                                    className={styles.ageGateInput}
                                />
                            </div>
                        )}
                        {calendar === "MES" && (
                            <div className={styles.ageGateNarrowInputContainer}>
                                <input
                                    required
                                    maxLength={1}
                                    max={1}
                                    name={"M1"}
                                    onChange={handleAge}
                                    placeholder="M"
                                    value={date.mm[0]}
                                    ref={inputMonth1}
                                    type="text"
                                    pattern="[0-9]*"
                                    className={styles.ageGateInput}
                                />
                                <input
                                    required
                                    maxLength={1}
                                    max={2}
                                    name={"M2"}
                                    onChange={handleAge}
                                    placeholder="M"
                                    value={date.mm[1]}
                                    ref={inputMonth2}
                                    type="text"
                                    pattern="[0-9]*"
                                    className={styles.ageGateInput}
                                />
                            </div>
                        )}
                        {calendar === "DÍA" && (
                            <div className={styles.ageGateNarrowInputContainer}>
                                <input
                                    max={3}
                                    required
                                    name={"D1"}
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength={1}
                                    ref={inputDay1}
                                    placeholder="D"
                                    value={date.dd[0]}
                                    onChange={handleAge}
                                    className={styles.ageGateInput}
                                />
                                <input
                                    max={9}
                                    required
                                    name={"D2"}
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength={1}
                                    placeholder="D"
                                    ref={inputDay2}
                                    value={date.dd[1]}
                                    onChange={handleAge}
                                    className={styles.ageGateInput}
                                />
                            </div>
                        )}
                        <div className={styles.ageGateRememberMe}>
                            <input
                                className={styles.ageGateRadio}
                                type="radio"
                                checked={remember}
                                onClick={() => {
                                    setRemember(!remember);
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Age Gate",
                                        event_action: "Intercation",
                                        event_label: "Recuerdame",
                                        interaction: false,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            />
                            <div className={styles.ageGateWarning}>
                                Recordame (no marcar si compartís la
                                computadora)
                            </div>
                        </div>
                        <button className={styles.ageGateButton} type="submit">
                            Ingresar
                        </button>
                    </form>
                </div>
            </div>
            <AgeGateFooter />
        </>
    );
};

export default AgeGate;
