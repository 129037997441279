export const calculateAge = (birthday) => {
  let ageDate = new Date(Date.now() - birthday.getTime());
  if (Math.abs(ageDate.getUTCFullYear() - 1970) >= 18) return true;
  return false;
};

export const calculateMonth = (birthday) => {
  let ageDate = new Date(Date.now() - birthday.getTime());
  let today = new Date();
  let birthdayM = birthday.getMonth();
  if (
    Math.abs(ageDate.getUTCFullYear() - 1970) >= 18 &&
    birthdayM < today.getMonth()
  )
    return true;
  return false;
};

export const calculateDate = (birthday) => {
  let today = new Date();
  if (birthday.getDate() <= today.getDate()) return true;
  return false;
};

export const toNumbers = (arr) => {
  let string = "";
  arr.map((str) => {
    string += str;
  });
  return Number(string);
};

export const IsMobileDevice = () => {
  //return if device is mobile
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  )
}