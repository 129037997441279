import { atom, selector } from "recoil"

export const ageGatePassedAtom = atom({
  key: "ageGatePassedAtom",
  default: false,
})

export const showLoaderAtom = atom({
  key: "showLoaderAtom",
  default: true,
})

export const showModalAtom = atom({
  key: "showModalAtom",
  default: true,
})

export const showNewsletterModalAtom = atom({
  key: "showNewsletterModalAtom",
  default: true,
})

export const showStarAtom = atom({
  key: "showStarAtom",
  default: true,
})

export const showFooterAtom = atom({
  key: "showFooterAtom",
  default: false,
})

export const showNewsFooterAtom = atom({
  key: "showNewsFooterAtom",
  default: false,
})

export const currentHeritageSlideAtom = atom({
  key: "currentHeritageSlideAtom",
  default: 1,
})

export const isMobileAtom = atom({
  key: "isMobileAtom",
  default: false,
})

export const showCartDrawerAtom = atom({
  key: "showCartDrawerAtom",
  default: false,
})

export const productsAtom = atom({
  key: "productsAtom",
  default: [],
})

const getCartFromLocalStorage = async () => {
  try {
    const item = window.localStorage.getItem("cart")
    return item ? JSON.parse(item) : []
  } catch (err) {
    console.log(err)
  }
}

export const cartAtom = atom({
  key: "cartAtom",
  default: getCartFromLocalStorage(),
})

export const cartTotalItemsAtom = atom({
  key: "cartTotalItemsAtom",
  default: 0,
})

export const cartTotalItemsSelector = selector({
  key: "cartTotalItemsSelector",
  get: ({ get }) => {
    const cart = get(cartAtom)
    let count = 0
    if (cart)
      cart.forEach((item) => {
        count = item.quantity + count
        return count
      })
    return Number(count)
  },
})

export const cartMoneyTotalSelector = selector({
  key: "cartMoneyTotalSelector",
  get: ({ get }) => {
    const cart = get(cartAtom)
    let count = 0
    if (Array.isArray(cart)) {
      cart.forEach((item) => {
        count = count + item.quantity * item.variants[item.variantIndex].price
        return count
      })
    }
    return count
  },
})